import {
  isMobile,
  isTablet,
  isSmartTV,
  isWearable,
  isConsole,
  isAndroid,
  isWinPhone,
  isIOS
} from 'react-device-detect';

export default function detectMobile() {
  return(
    isMobile ||
    isTablet ||
    isSmartTV ||
    isWearable ||
    isConsole ||
    isAndroid ||
    isWinPhone ||
    isIOS)
}