import detectMobile from './detect-mobile'

export default function scrollTo(selector) {
  const element = document.querySelector(selector)
  const isBrowser = (typeof window !== 'undefined')
  
  if (element && isBrowser) {
    const navHeight = localStorage.getItem('site:navHeight') ?? 0
    const offset = element.offsetTop - navHeight
    // console.log(`scrolling: navHeight=${navHeight}, offset=${offset}`)
    window.scrollTo({
      top: offset > 0 ? offset : 0,
      left: 0,
      behavior: detectMobile() ? 'auto' : 'smooth',
    })
    history.replaceState({}, document.title, window.location.pathname)
    return true;
  }

  return false
}
